<template>
    <div>
        <v-dialog v-model="dialog" persistent width="300" content-class="scrollQr">
            <v-card style="overflow: hidden">
                <v-container>
                    <v-row class="head-bc">
                        <v-col cols="12" class="title-head-bc title-lab">
                            <img src="/iconVirtuWhite.png" alt="Icon Virtu" style="max-width: 75px" />
                        </v-col>
                        <v-col cols="12" class="title-head-bc2 pb-4" style="margin-top: 15px; margin-bottom: -10px">
                            {{ this.datQr.plant }}
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-text class="padding-card">
                    <v-row style="padding-left: 5px">
                        <v-col cols="12" class="title-bc-rgb" style="padding-top: 15px; padding-bottom: 0">
                            {{ this.datQr.order_number }}
                        </v-col>
                        <v-col cols="12" class="title-bc-plant">
                            {{ this.datQr.patient }}
                        </v-col>

                        <v-col cols="6" class="title-head-bc2">
                            <div class="title-bc-rgb">
                                {{ $t("page.profile.date") }}
                            </div>
                            <div class="title-bc">
                                {{ formatDate(this.datQr.booking_date) }}
                            </div>
                        </v-col>
                        <v-col cols="6" class="title-head-bc2" style="text-align: end">
                            <div class="title-bc-rgb">
                                {{ $t("page.profile.estimed_time") }}
                            </div>
                            <div class="title-bc" style="text-align: end">
                                {{ this.datQr.estimated_service_at }}
                            </div>
                        </v-col>
                        <v-col cols="12" class="title-head-bc2 pb-6" style="margin-top: -5px">
                            <div class="title-bc-rgb">
                                {{ $t("page.profile.type_of_appointment") }}
                            </div>
                            <!-- 
                 v-for="(test, index) of datQr"
                :key="index" 
                -->
                            <!-- v-for="(test, index) of this.datQr.product_order" 
                :key="index"
                -->
                            <div class="title-bc" v-for="(test, index) of this.datQr.product_order" :key="index">
                                <div class="overflow-elipse" style="text-align: left !important; margin-bottom: -7px">
                                    {{ test }}
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text style="position:relative;br-bottom-barcode">
                    <div class="strip">
                        <div class="strip-item"></div>
                        <div class="strip-item"></div>
                        <div class="strip-item"></div>
                        <div class="strip-item"></div>
                        <div class="strip-item"></div>
                        <div class="strip-item"></div>
                        <div class="strip-item"></div>
                        <div class="strip-item"></div>
                    </div>
                    <v-col cols="12" class="d-flex align-center justify-center" style="padding: 35px 50px 0px 50px">
                        <qrcode-vue :value="this.datQr.barcode" :size="150" level="H" :background="foreColor" :foreground="backColor"></qrcode-vue>
                    </v-col>
                    <v-col cols="12" class="d-flex align-center justify-center pb-1 title-bc">
                        {{ $t("have_nice") }}
                    </v-col>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from "moment";
import QrcodeVue from "qrcode.vue";
//import icons from "@/components/elements/Icons.vue";
import Auth from "@/service/auth_service";
import API from "@/service/api_service";

export default {
    components: {
        QrcodeVue,
        //icons
    },
    data: () => ({
        //backColor: "#FFFFFF",
        //foreColor: "#00a4b3",
        // backColor: "#FFFFFF",
        // foreColor: "#000000",
        backColor: "#000000",
        foreColor: "#FFFFFF",
        dialog: true,
        id: "",
        datQr: {
            plant: "",
            order_number: "",
            booking_date: "",
            estimated_service_at: "",
            product_order: [],
            patient: "",
            barcode: "",
        },
        testArr: ["aaa", "bbb"],
    }),
    async mounted() {
      const crypt = require("crypto");
        const key = "2707ac1992b6e77fa20a2594b2cb850f";
        const iv = "cca99e79b377a41d";
        const algorithm = "aes-256-cbc";
        const textEnkrip = this.$route.query.dat;
        var decrypter = crypt.createDecipheriv(algorithm, key, iv);
        let decrypt = decrypter.update(textEnkrip, "hex", "utf8");
        decrypt += decrypter.final("utf8");
        this.id = decrypt.replace(/['"]+/g, '');
        console.log(this.id,'xxxx')
        

        // const je = require("json-encrypt");
        // const decrypt = je.decrypt(this.$route.query.dat);
        // const order_number = je.decrypt(this.$route.query.on);
        // const booking_date = je.decrypt(this.$route.query.bd);
        // const estimated_service_at = je.decrypt(this.$route.query.esa);
        // const product_or = je.decrypt(this.$route.query.po);
        // const patient_name = je.decrypt(this.$route.query.pt);
        // const booking_code = je.decrypt(this.$route.query.bc);
        // const product_orx =  product_or.replace(/[^a-zA-Z0-9 ,]+/g, '');
        // const product_order = product_orx.split(",");

        // this.id = JSON.parse(decrypt);
        // if (this.$route.query.pl !== "undefined") {
        //     const plant = je.decrypt(this.$route.query.pl);
        //     this.datQr.plant = plant.replace(/['"]+/g, "");
        //     // const textPlant = this.$route.query.pl;
        //     // console.log("pl:", textPlant);
        //     // var plant = decrypter.update(textPlant, "hex", "utf8");
        //     // plant += decrypter.final("utf8");
        //     // console.log("plant:", JSON.parse(plant).replace(/['"]+/g, ""));
        //     // this.dataQr.plant = plant.replace(/['"]+/g, "");
        // } else {
        //     const plant = "";
        //     this.datQr.plant = plant;
        // }

        // this.datQr.order_number = order_number.replace(/['"]+/g, '');
        // this.datQr.booking_date = booking_date.replace(/['"]+/g, '');
        // this.datQr.estimated_service_at = estimated_service_at.replace(/['"]+/g, '');
        // this.datQr.product_order = product_order;
        // this.datQr.patient = patient_name.replace(/['"]+/g, '');
        // this.datQr.barcode = booking_code.replace(/['"]+/g, '');
        await API.get(`${process.env.VUE_APP_API_TRUST}registration/share-order?uuid=${this.id}`, {
            Authorization: `Bearer ${Auth.getToken()}`,
        }).then((res) => {
            if (res.statusCode === 200) {
                //var data = res.results.find((element) => element.id == this.id);
                var data = res.results[0];
                if (typeof data.plant !== "undefined") {
                    this.datQr.plant = data.plant.substr(13);
                } else {
                    this.datQr.plant = data.plant;
                }
                this.datQr.order_number = data.order_number;
                this.datQr.booking_date = data.booking_date;
                this.datQr.estimated_service_at = data.estimated_service_at;
                this.datQr.product_order = data.product_order;
                this.datQr.patient = data.patient_name;
                this.datQr.barcode = data.booking_code[0];
            }
        });
    },
    methods: {
        formatDate(par) {
            if (!par) return null;
            return moment(par).format("DD-MMM-YYYY").toUpperCase();
        },
    },
};
</script>
<style lang="scss">
.scrollQr {
    border-radius: 25px !important;
    max-height: 95% !important;
}
::-webkit-scrollbar {
    width: 0px !important;
    background: transparent !important; /* make scrollbar transparent */
}
</style>
<style lang="scss" scoped>
//barcode
.head-bc {
    background: #00a4b3;
    padding: 0 15px;
}
.ic-home {
    padding-bottom: 0px;
    height: 20px;
}
.padding-card {
    padding: 20px 24px !important;
}
.title-head-bc {
    padding-bottom: 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: #ffffff;
    margin-bottom: -10px;
}
.title-head-bc2 {
    padding-bottom: 0px;
    font-style: normal;
    font-size: 14px;
    color: #ffffff;
    padding-top: 10px;
}
.title-lab {
    height: 40px;
    margin-top: -15px;
}
.title-bc {
    color: #000000;
    font-weight: 400;
    font-size: 12px;
}
.title-bc-plant {
    font-size: 14px;
    color: #000000;
    margin-top: -12px;
    margin-bottom: -15px;
    font-weight: 500;
}
.title-bc-rgb {
    color: #00a4b3;
    margin-bottom: -5px;
    font-size: 14px !important;
}
.ic-close {
    position: absolute;
    top: 0;
    right: 0;
}
.buletL {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    background: white;
    box-shadow: aquamarine;
    box-shadow: inset 0 0 3px grey;
}
.buletR {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    right: 0;
    position: absolute;
    top: 0;
    transform: translate(50%, -50%);
    background: white;
    box-shadow: aquamarine;
    box-shadow: inset 0 0 3px grey;
}
.strip {
    position: absolute;
    height: 10px;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-around;
    transform: translateY(-50%);
    //padding: 0 30px;
}
.strip-item {
    border-radius: 10px;
    width: 25px;
    background: #00a4b3;
    box-shadow: inset 0 0 3px grey;
}
.br-top-barcode {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
}
.br-bottom-barcode {
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
}
.btn-cancel-qr {
    width: 25px !important;
    height: 25px !important;
    border-radius: 100% !important;
    min-width: 25px !important;
    background: white;
    margin-right: -20px;
    margin-top: -35px;
}
.button-qr {
    border-radius: 4px;
    color: #33344f !important;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    //   text-transform: capitalize;
    // height: 30px !important;
    // min-width: 105px !important;
}
</style>